import { callWithNuxt, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp()

    const {status} = useAuth();

    if (status?.value === 'unauthenticated') {
        return callWithNuxt(nuxtApp, () => navigateTo('/login'))
    }
})
